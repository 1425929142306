/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
/* mphasis */
export const AppConfigCustom = {
  "APPAK": "web-be801a5d-ee09-46b0-a99c-e6f46f5aad5d",
  "CLOUD_REGION": "ap-south-1",
  "COGNITO_USER_POOL": "ap-south-1_mA0ovhUuB",
  "COGNITO_USER_POOL_CLIENT_ID": "12osol89k86ie0k4k1g779jhal",
  "ADDITIONAL_HEADERS" : {
    "x-apigw-api-id":"8n16g788z1"
  },
  "ENDPOINT": "https://uncannyapi.mphasis.com/Prod",
  "ES_ENDPOINT": "https://uncannyapi.mphasis.com/Prod",
  "frontendSecretKey":"mphasis-dashboard",
  "JM_PROXY_ENDPOINT": "",
  "allowCamConfig": false,
  "allowLocal": false,
  "emailSuffix" : "@remotifai.com",
  "allowBoundingBox":false,
  "allowAuditConfig":true,
  "allowEventReviews":true,
  "autoUpdateEvent": true,
  "eventQueryLimit": 1000,
  "eventDownloadLimit": 1000,
  "agentStatusTimer":5, // in mins
  "agentStatusEnabled":true,
  "dashboardEventQueryLimit": 100,
  "SHOW_VIOLATION":false,
  "ENABLE_REFRESH_VIOLATION":false,
  "eventRefreshInterval": 30000,
  "imageFeedRefreshInterval": 20000,
  "parkingFeedRefreshInterval": 5000,
  "jpegPlayerRefreshInterval": 2000,
  "IDLE_TIMEOUT" : 1000*60*30,
  SHOW_DIRECT_LINK_EVENT: false,
  "ENABLE_REPORT_DOWNLOAD": true,
  "PDF_LIMIT":9000,
  "EXCEL_LIMIT":30000,
  "NAME": "Uncanny Vision",
  "mapK": "AhBVLeZEl9lnaie15GuW_pVIAUAMcmsWT-IGiDBmVLmBIVsaG0hMn9bm6ZN_yvDj",
  "viewMode": "List",
  "adminDashboard": "/orgs",
  "ES_EVENT_TYPES": {},
  "DEFAULT_ZONEINFO": 'Asia/Calcutta',
  "userDefaultSettings": {
    ALERT_EMAIL: "OFF",
    EVENT_VISIBILITY: "0",
    EVENT_EMAIL_ALERT: "3",
    CAMERAS: [],
  },
  "userRoles": [
    {
      name: 'ADMIN',
      key: 'ADMIN',
    },
    {
      name: 'REGULAR',
      key: 'REGULAR',
    },
  ],
  placeTypes: [],
  camTypes: [],
  orgTypes: []
}