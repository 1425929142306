/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import * as urlParser from 'url-parse';
import * as qs from 'qs';
import Tooltip from '@material-ui/core/Tooltip';
import { AppStyles } from '../styles/AppTheme';
import moment from 'moment';
import { AppConfig } from '../AppConfig';
import { Controller } from '../common/Controller';

export class AppHelper {
  static buttonEdit(caller, current) {
    return (
      <Tooltip title='Edit' placement='top-start' key={current.id + 'editTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons' onClick={() => caller.openModalForEdit(current)}>edit</i>
        </span>
      </Tooltip>
    )
  }

  static getCamAltName(selectedOrg) {
    let camAltName = 'Cam';
    for (const f of AppConfig.orgTypes) {
      if (f.key === selectedOrg.type) {
        if (f.camAltName) {
          camAltName = f.camAltName
        } else {
          camAltName = 'Cam'
        }
        break
      } else {
        camAltName = 'Cam'
      }
    }
    return camAltName

  }

  static getPlaceAltName(selectedOrg) {
    let placeAltName = 'Place';
    for (const f of AppConfig.orgTypes) {
      if (f.key === selectedOrg.type) {
        if (f.placeAltName) {
          placeAltName = f.placeAltName
        } else {
          placeAltName = 'Place'
        }
        break
      } else {
        placeAltName = 'Place'
      }
    }
    return placeAltName
  }

  static buttonDelete(caller, current) {
    return (
      <Tooltip title='Delete' placement='top-start' key={current.id + 'deleteTT'}>
        <span className={css(AppStyles.entityTableLinkIcon)}>
          <i className='material-icons' onClick={() => caller.openModalForDelete(current)}>delete</i>
        </span>
      </Tooltip>
    )
  }

  static getPriorityStr = (priority) => {
    let priorityStr = '';
    priority = Number(priority)
    if (priority === 0) {
      priorityStr = 'Very low'
    }
    else if (priority === 1) {
      priorityStr = 'Low'
    }
    else if (priority === 2) {
      priorityStr = 'Medium'
    }
    else if (priority === 3) {
      priorityStr = 'High'
    }
    return priorityStr;
  }

  static arrayToCsv(items) {
    //return items
    if (!items) return null
    let csv = ''
    let index = 0
    for (const item of items) {
      index = index + 1
      if (items.length > index) {
        csv = csv + item + ','
      } else {
        csv = csv + item
      }
    }
    return csv
  }

  static getPlacetype(type) {
    if (!AppConfig.placeTypes) {
      return null
    }
    for (const placeType of AppConfig.placeTypes) {
      if (placeType.key === type) {
        return placeType.name
      }
    }
    return null
  }

  static attachTsToURL = (item) => {
    if (item.cams) {
      if (item.cams.length > 0) {
        item.cams.sort((a, b) => a.name.localeCompare(b.name)) //Sorted alphabetically
      }
      for (const cam of item.cams) {
        if (!cam.urlJpeg || cam.urlJpeg.length === 0)
          continue
        const urlParsed = new urlParser(cam.urlJpeg);
        const currQueryString = (urlParsed.query).substring(1);
        const queryParams = qs.parse(currQueryString);
        queryParams.timestamp = moment().valueOf();
        const modifiedQueryString = qs.stringify(queryParams);
        cam.urlJpeg = urlParsed.origin + urlParsed.pathname + '?' + modifiedQueryString;
      }
    }
    return item;
  }

  static getAgentStatus = (cam) => {
    if (!cam.latestEventTs) {
      return "newAgent"
    }
    let time = cam.lastAgentSyncTs
    if (!time) {
      return 'offline';
    }
    const startDate = moment.utc(time);  
    const endDate = moment.utc();
    const duration = moment.duration(endDate.diff(startDate));

    if (duration.days() == 0 && duration.hours() == 0 && duration.minutes() <= AppConfig.agentStatusTimer) {
      return 'online'
    } else {
      return 'offline'
    }

  }

  static getAgentSystemStatus = (cam) => {
    if(cam && cam.settings &&  cam.settings.lock &&   cam.settings.lock.enabled == "YES" && cam.settings.enableLockFeature &&   cam.settings.enableLockFeature.enabled == "YES"){
      return "locked"
    }
    else{
      return "unlocked"
    }
  }

  static getTags(type) {
    for (const pType of AppConfig.placeTypes) {
      if (!type && pType.key === 'wfhTeams') {//TODO: hack, will be deprecated later
        return pType.tags
      }
      if (pType.key === type) {
        return pType.tags
      }
    }
    return []
  }

  static getGlobalPriotiyValue() {
    this.userSettings = Controller.get().userMgr().getUserSettings()
    return Number(this.userSettings.EVENT_VISIBILITY)
  }

  static sortItemsByName(items) {
    if (!items) return []

    return items.sort((a, b) => {
      const str1 = a.name
      const str2 = b.name
      return str1.localeCompare(str2)
    })
  }

  static renderPlaceid = (item, orgPlaces) => {
    for (const o of orgPlaces) {
      for (const c of o.cams) {
        if (c._id === item.camId) {
          return o._id
        }
      }
    }
  }

  static renderComment = (item, orgPlaces) => {
    var text =  ""
    for(var i = 0;i<item.reviews.length;i++){
      let element  = item.reviews[i];
      var nameOfmanager = element.name? element.name:element.email
      var commentByManager = element.comment? element.comment:"no comment done"
      
      text += `${nameOfmanager.toUpperCase()}  :  ${commentByManager}\n
      `
    }
    return text
  }

  static renderPlaceName = (item, orgPlaces) => {
    for (const o of orgPlaces) {
      for (const c of o.cams) {
        if (c._id === item.camId) {
          return o.name
        }
      }
    }
  }

  static renderComment = (item, orgPlaces) => {
    var text =  ""
    for(var i = 0;i<item.reviews.length;i++){
      let element  = item.reviews[i];
      var nameOfmanager = element.name? element.name:element.email
      var commentByManager = element.comment? element.comment:"no comment done"
      
      text += `${nameOfmanager.toUpperCase()}  :  ${commentByManager}\n
      `
    }
    return text
  }
  
  static renderCamName = (item, orgPlaces) => {
    for (const o of orgPlaces) {
      for (const c of o.cams) {
        if (c._id === item.camId) {
          return c.name
        }
      }
    }
  }

  static showSettings(item, key) {
    if(key === 'org') {
      for (const f of AppConfig.orgTypes) {
        if (f.key === item.type) {
          return f.features
        }
      }
      return false
    } else if (key === 'place') {
      for (const pType of AppConfig.placeTypes) {
        if(item.type !== pType.key) {
          continue
        }
        if(pType.features) {
          return true
        }
      }
      return false
    } else if (key === 'cam') {
      for (const pType of AppConfig.camTypes) {
        if(item.type !== pType.key) {
          continue
        }
        if(pType.features) {
          return true
        }
      }
    }
    return false
  }

  static getSelectAllOption() {
    return { name: 'Select All', key: 'selectAll' }
  }

  static getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  static isHealthy(item, type) {
    if(!item.lastSeenTs) {
      return false
    }
    const seconds = moment().diff(moment(item.lastSeenTs), 'seconds')

    if(type === 'device') {
      if(seconds > AppConfig.DEVICE_HEALTH_CHECK_DURATION_SECONDS) {
        return false
      }
    }
    return true
  }
}
